import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CartwheelButtonComponent, CartwheelIconButtonComponent } from '@cartwheel/web-components';

@Component({
  standalone: true,
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  imports: [CartwheelButtonComponent, CartwheelIconButtonComponent]
})
export class ConfirmDialogComponent implements OnInit {
  private confirmationAction: () => void;
  private cancellationAction: () => void;
  public message: string;

  constructor(
    private dialog: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ConfirmDialogData
  ) { }

  ngOnInit() {
    this.confirmationAction = this.data.confirmationAction;
    this.cancellationAction = this.data.cancellationAction;
    this.message = this.data.message;
  }

  public closeDialog() {
    if (this.cancellationAction) {
      this.cancellationAction();
    }
    this.dialog.close();
  }

  public confirm() {
    this.confirmationAction();
    this.closeDialog();
  }
}

interface ConfirmDialogData {
  confirmationAction: () => void;
  cancellationAction?: () => void;
  message: string;
}
